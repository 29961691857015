import { Injectable } from '@angular/core';
import { CanLoad, Route } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../auth';
import { take } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanLoad {

  constructor(private authService: AuthService) { }

  canLoad(
    route: Route
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isAuthenticated.pipe(take(1));
  }
}

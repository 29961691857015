import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { CacheService } from './cache.service';

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private cacheService: CacheService
  ) { }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    /**
     * With Caching
     */
    return this.cacheService.get(
      path,
      this.http
        .get(`${environment.api_url}${path}`, { params })
        .pipe(catchError(this.formatErrors))
    );

    /**
     * Without Caching
     */
    // return this.http
    //   .get(`${environment.api_url}${path}`, { params })
    //   .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http
      .post(`${environment.api_url}${path}`, this.isFormData(body) ? body : JSON.stringify(body))
      .pipe(catchError(this.formatErrors));
  }

  private isFormData(value) {
    return value instanceof FormData;
  }
}

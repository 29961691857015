import { Injectable } from '@angular/core';

import * as SecureLS from 'secure-ls';

import { environment } from '../../environments/environment';

const SECRET = environment.encryption.secret;

const encryptionMethod = {
  encodingType: 'rc4',
  isCompression: false,
  encryptionSecret: SECRET
};

const ls = new SecureLS(encryptionMethod);

interface Credentials {
  email: string;
  password: string;
}

@Injectable()
export class CredentialsService {
  saveCredentials(credentials: Credentials) {
    ls.set('email', credentials.email);
    ls.set('password', credentials.password);
  }

  getCredentials(): Credentials {
    return {
      email: ls.get('email'),
      password: ls.get('password')
    };
  }

  purgeCredentials(): void {
    ls.remove('email');
    ls.remove('password');
  }

}

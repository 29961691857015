/**
 * This file is replaced in production by the compiler
 * for config.prod.ts.
 */

export const config = {
  /**
   * Max cache age
   * By default all GET requests are cached
   */
  cache_max_age: 10000, // 10 secs
};

import { Pipe, PipeTransform, Input } from '@angular/core';

/**
 * @see https://www.fueltravel.com/blog/migrating-from-angular-1-to-2-part-1-pipes/
 * @description Transform pipe for ordering ngFor items by property.
 * @example <li *ngFor="let item of items | orderBy">{{item}}</li>
 * @example <li *ngFor="let item of items | orderBy : '-'">{{item}}</li>
 * @example <li *ngFor="let item of items | orderBy : 'someProperty'">{{item}}</li>
 * @example <li *ngFor="let item of items | orderBy : ['-someProperty', 'otherProperty']">{{item}}</li>
 */
@Pipe({ name: 'orderBy', pure: false })
export class OrderByPipe implements PipeTransform {

  static orderByComparator(a: any, b: any): number {
    if ((isNaN(parseFloat(a)) || !isFinite(a)) || (isNaN(parseFloat(b)) || !isFinite(b))) {
      if (String(a).toLowerCase() < String(b).toLowerCase()) { return -1; }
      if (String(a).toLowerCase() > String(b).toLowerCase()) { return 1; }
    } else {
      if (parseFloat(a) < parseFloat(b)) { return -1; }
      if (parseFloat(a) > parseFloat(b)) { return 1; }
    }
    return 0;
  }

  transform(value: any, [config = '+']): any {
    if (!Array.isArray(value)) { return value; }

    if (!Array.isArray(config) || (Array.isArray(config) && config.length === 1)) {
      const propToCheck: string = !Array.isArray(config) ? config : config[0];
      const desc = propToCheck.substr(0, 1) === '-';

      if (!propToCheck || propToCheck === '-' || propToCheck === '+') {
        return !desc ? value.sort() : value.sort().reverse();
      } else {
        const prop: string = propToCheck.substr(0, 1) === '+' || propToCheck.substr(0, 1) === '-'
          ? propToCheck.substr(1)
          : propToCheck;

        return value.sort((a: any, b: any) => {
          return !desc
            ? OrderByPipe.orderByComparator(a[prop], b[prop])
            : -OrderByPipe.orderByComparator(a[prop], b[prop]);
        });
      }
    } else {
      return value.sort((a: any, b: any) => {
        for (let i = 0; i < config.length; i++) {
          const desc = config[i].substr(0, 1) === '-';
          const prop = config[i].substr(0, 1) === '+' || config[i].substr(0, 1) === '-'
            ? config[i].substr(1)
            : config[i];

          const comparison = !desc
            ? OrderByPipe.orderByComparator(a[prop], b[prop])
            : -OrderByPipe.orderByComparator(a[prop], b[prop]);

          if (comparison !== 0) { return comparison; }
        }
        return 0;
      });
    }
  }
}

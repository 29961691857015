import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from './shared';

import {
  ApiService,
  CacheService,
  CredentialsService
} from './services';

import { HttpAuthInterceptor } from './interceptors';

import { NotFoundComponent } from './views';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './guards';

const SERVICES = [
  ApiService,
  CacheService,
  CredentialsService
];

const GUARDS = [
  AuthGuard
];

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    LoadingBarHttpClientModule
  ],
  providers: [
    ...SERVICES,
    ...GUARDS
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpAuthInterceptor,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';

import { Subject, Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { config } from '../config/config';

interface CacheContent {
  expiry: number;
  value: any;
}

@Injectable()
export class CacheService {
  private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
  private inFlightObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();
  private maxAge = config.cache_max_age;

  get(key: string, fallback?: Observable<any>, maxAge?: number): Observable<any> | Subject<any> {
    if (this.hasValidCachedValue(key)) {
      return of(this.cache.get(key).value);
    }

    maxAge = maxAge ? maxAge : this.maxAge;

    if (this.inFlightObservables.has(key)) {
      return this.inFlightObservables.get(key);
    } else if (fallback && fallback instanceof Observable) {
      this.inFlightObservables.set(key, new Subject());

      return fallback.pipe(
        tap(value => this.set(key, value, maxAge))
      );
    } else {
      return throwError('Requested Key Is Not Present In Cache');
    }
  }

  set(key: string, value: any, maxAge: number = this.maxAge): void {
    this.cache.set(key, {
      value: value,
      expiry: Date.now() + maxAge
    });

    this.notifyInFlightObservers(key, value);
  }

  has(key: string): boolean {
    return this.cache.has(key);
  }

  private notifyInFlightObservers(key: string, value: any): void {
    if (this.inFlightObservables.has(key)) {
      const inFlight = this.inFlightObservables.get(key);
      const observersCount = inFlight.observers.length;

      if (observersCount) {
        inFlight.next(value);
      }

      inFlight.complete();
      this.inFlightObservables.delete(key);
    }
  }

  private hasValidCachedValue(key: string): boolean {
    if (this.cache.has(key)) {
      if (this.cache.get(key).expiry < Date.now()) {
        this.cache.delete(key);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}

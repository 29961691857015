import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, ReplaySubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { PartnerUser } from '../models';
import { CredentialsService } from '../services';

import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {
  private currentUserSubject = new BehaviorSubject<PartnerUser>({} as PartnerUser);
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);

  currentUser = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private http: HttpClient,
    private credentialsService: CredentialsService,
    private router: Router
  ) { }

  attemptAuth(credentials: any): Observable<PartnerUser> {
    const headers = new HttpHeaders({
      'Email': credentials.email,
      'Password': credentials.password,
      'Accept': 'application/json'
    });

    return this.http.get(`${environment.api_url}partner/login`,
      { headers: headers }
    ).pipe(map((data: any) => {
      if (data.success && data.user) {

        if (data.user.auth_level === environment.allowedAuthLevel) {
          this.setAuth(data.user, credentials);
          return data.user;
        } else {
          throw new Error('This account does not have the required permissions.');
        }
      } else {
        throw new Error(data.message);
      }
    }));
  }

  getCurrentUser(): PartnerUser {
    return this.currentUserSubject.value;
  }

  populate(): void {
    const credentials = this.credentialsService.getCredentials();

    if (credentials.email !== '' || credentials.password !== '') {
      this.attemptAuth(credentials).subscribe(
        data => {
          if (data.auth_level !== environment.allowedAuthLevel) {
            this.purgeAuth();
          } else {
            this.setAuth(data, credentials);
          }
        },
        error => this.purgeAuth()
      );
    } else {
      this.purgeAuth();
    }
  }

  purgeAuth(): void {
    this.credentialsService.purgeCredentials();
    this.currentUserSubject.next({} as PartnerUser);
    this.isAuthenticatedSubject.next(false);
    this.router.navigate(['/login']);
  }

  setAuth(user: PartnerUser, credentials: any) {
    this.credentialsService.saveCredentials(credentials);
    this.currentUserSubject.next(user);
    this.isAuthenticatedSubject.next(true);
  }

}

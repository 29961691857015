import { NgModule } from '@angular/core';

import { SharedModule } from '../shared';
import { AuthComponent } from './auth.component';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthService } from './auth.service';

@NgModule({
  declarations: [AuthComponent],
  imports: [SharedModule, AuthRoutingModule],
  exports: [],
  providers: [AuthService],
})
export class AuthModule { }

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OrderByPipe } from './pipes';


const MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  FontAwesomeModule,
];

const PIPES = [
  OrderByPipe
];

@NgModule({
  declarations: [
    ...PIPES
  ],
  imports: [
    ...MODULES
  ],
  exports: [
    ...MODULES,
  ],
  providers: [],
})
export class SharedModule { }

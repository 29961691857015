import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  form: FormGroup;
  submitting = false;
  message: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) {
    this.form = this.formBuilder.group({
      email: new FormControl({ value: '', disabled: this.submitting }, Validators.required),
      password: new FormControl({ value: '', disabled: this.submitting }, Validators.required)
    });
  }

  ngOnInit(): void { }

  submitForm(): void {
    this.submitting = true;

    const payload = this.form.value;
    this.authService.attemptAuth(payload).subscribe(
      data => this.router.navigateByUrl('/partner'),
      error => {
        this.submitting = false;
        this.message = error.message;
      }
    );
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { NotFoundComponent } from './views';
import { AuthGuard } from './guards';

const routes: Routes = [
  {
    path: 'partner',
    loadChildren: './features/partner/partner.module#PartnerModule',
    canLoad: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'partner',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Preload all modules for now
    // If the app grows and slows, leave all modules
    // to lazy load.
    preloadingStrategy: PreloadAllModules,
    // Set to true for development
    // enableTracing: true,
    scrollPositionRestoration: 'top'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
